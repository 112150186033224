@import "../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/legacy/ag-theme-material-v22-compat";
@import "./styles/colors.modules.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


* {
  font-family: 'Roboto', sans-serif !important;
}

:not(.MuiTooltip-popper, .MuiAutocomplete-popper, .dxm-tooltip, .dxm-tooltip svg, #vector-map) {
  transition: all 0.4s
}

.dxm-tooltip {
  z-index: 10000;
}
.annotationLayer {
  display: none;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.MuiFormControl-marginDense {
  margin-top: 0 !important;
}

.ag-icon {
  font-family: "agGridMaterial" !important;
}

.ag-root ::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em
}

::-webkit-scrollbar {
  width: 7px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important; 
  border-radius: 0 !important;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, .3) !important
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $primaryBlue !important;
  border-radius: 0 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primaryBlueHover;
}

.ag-theme-material .ag-cell-inline-editing {
  border: none !important
}

.ag-cell-focus {
  border: none !important;
}

.ag-react-container .ag-grid-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

div[col-id="operations"] .ag-header-cell-label,
div[col-id="info"] .ag-header-cell-label,
[col-id="operations"] .ag-react-container,
[col-id="info"] .ag-react-container {
  justify-content: center;
  text-align: center;
  overflow: auto;
}

div[col-id="operations"] .ag-header-cell-label::-webkit-scrollbar,
div[col-id="info"] .ag-header-cell-label::-webkit-scrollbar,
[col-id="operations"] .ag-react-container::-webkit-scrollbar,
[col-id="info"] .ag-react-container::-webkit-scrollbar {
  display: none
}

[col-id="operations"] .ag-cell-wrapper,
[col-id="info"] .ag-cell-wrapper, 
[col-id="content"] .ag-cell-wrapper {
  justify-content: center !important;
  text-align: center !important;
  align-items: baseline;
}

.MuiOutlinedInput-root {
  border-radius: 0 !important;
}

#menu-appbar .MuiPaper-root.MuiMenu-paper ul.MuiMenu-list,
  #menu-appbar .MuiPaper-root.MuiMenu-paper{
  background-color: $primaryBlue !important;
  border-radius: 0;
}

canvas {
  border: 1px solid black
}

::-moz-selection { background: $primaryYellow; color: $white }
::selection { background: $primaryYellow; color: $white }

.green-row {
  background-color: #9de3bb !important;
}