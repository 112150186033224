$primaryYellow: #f3ca12;
$primaryBlue: #364354;
$primaryBlueHover: #284CCA;
$white: #fff;

:export {
    primaryYellow: $primaryYellow;
    primaryBlue: $primaryBlue;
    primaryBlueHover: $primaryBlueHover;
    white: $white;
}